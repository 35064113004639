import { render, staticRenderFns } from "./BranchListCreate.vue?vue&type=template&id=4af616b6"
import script from "./BranchListCreate.vue?vue&type=script&lang=js"
export * from "./BranchListCreate.vue?vue&type=script&lang=js"
import style0 from "./BranchListCreate.vue?vue&type=style&index=0&id=4af616b6&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports